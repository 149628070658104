/* This file contains all the constants for colors and font styles */

//$body-font:   ETbook, Palatino, "Palatino Linotype", "Palatino LT STD", "Book Antiqua", Georgia, serif;
// Note that Gill Sans is the top of the stack and corresponds to what is used in Tufte's books
// However, it is not a free font, so if it is not present on the computer that is viewing the webpage
// The free Google 'Lato' font is used instead. It is similar.
///
$base-font: "Ubuntu", sans-serif;
$base-font-family: "Ubuntu", sans-serif;
$base-font-size: 10px !default;
$content-font-size: 0.9rem;
$content-font-size-small: 0.7rem;
$note-font-size: 0.60rem;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.80 !default;
$base-line-height: 1.7 !default;
$small-line-height: 1.5 !default;

///
$sans-font:  "Gill Sans", "Gill Sans MT", "Lato", Calibri, sans-serif;
$code-font: Consolas, "Liberation Mono", Menlo, Courier, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #ffffff;
$background-color: $bg-color;
//$contrast-color: #a00000;
//$contrast-color: #194a50;
$contrast-color: #5f7782;
$contrast-color: #568EA6;
$contrast-color: #305F72;
$contrast-color: #325D79;

$border-color: #333333;
$link-style: color; // choices are 'color' or 'underline'. Default is color using $contrast-color set above

.small_image { width: 45px; height: 60px; }

@media only screen and (min-width: 400px) { .small_image { width: 90px; height: 120px; } }

.hide_toc{
    font-size: 0.99em;
}
