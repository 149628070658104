/* Share Bar */
.share_bar {
    border-top: 1px solid $contrast-color;
    font-size: 9px;
    text-align: center;
    font-weight: bold;
}

/* Title */
.share_title {
    margin-bottom: 2px;
//    font-weight: 500;
    color: $contrast-color;

    //font-size: 1.2em;
    font-size: $type-size-4;
    background: $background-color;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

/* All buttons */
.share_buttons {
}

/* Each button */
.share-button {
    margin-bottom: 0px;
    margin-right: 4px;
    padding: 8px 8px 8px 8px;
}
.share-button:hover {
    opacity: 0.85;
    color: #ffffff;
}

/* Facebook button */
.fa-facebook-official {
    color: #3b5998;
}
.fa-facebook-official:hover {
    background-color: #3b5998;
}

/* Twitter button */
.fa-twitter {
    color: #55acee;
}
.fa-twitter:hover {
    background-color: #55acee;
}

/* Google-PLus button */
.fa-google-plus {
    color: #dd4b39;
}
.fa-google-plus:hover {
    background-color: #dd4b39;
}

/* Pinterest button */
.fa-pinterest-p {
    color: #cb2027;
}
.fa-pinterest-p:hover {
    background-color: #cb2027;
}

/* Tumblr button */
.fa-tumblr {
    color: #32506d;
}
.fa-tumblr:hover {
    background-color: #32506d;
}

/* Reddit button */
.fa-reddit-alien {
    color: #ff4500;
}
.fa-reddit-alien:hover {
    background-color: #ff4500;
}

/* LinkedIn button */
.fa-linkedin {
    color: #007bb5;
}
.fa-linkedin:hover {
    background-color: #007bb5;
}

/* Email button */
.fa-envelope {
    color: #444444;
}
.fa-envelope:hover {
    background-color: #444444;
}
